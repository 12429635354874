import React from "react";

import Modal from "../Modal";
import Button from "../controls/Button";
import Input from "../controls/Input";
import Radio from "../controls/Radio";
import Toggle from "../controls/Toggle";

import { getDeviceTypeLabel } from "../../lib/api";
import { generateRandomString } from "../../lib/generate";
import {
  validateIpAddress,
  validatePasswordComplexity,
} from "../../lib/validation";

import { Device, NormalizedSite } from "../../lib/api";

const deviceSupportsSnmp = (device: Device) =>
  ["CVP", "RSU", "TSC"].includes(device.deviceType);

const deviceSupportsSsh = (device: Device) =>
  ["RSU"].includes(device.deviceType);

const SiteModal = ({
  confirmAction,
  headerText,
  intersectionIdsAndRegions,
  site: prevSite,
  siteNames,
}: {
  confirmAction: (site: NormalizedSite) => void;
  headerText: string;
  intersectionIdsAndRegions: string[];
  site?: NormalizedSite;
  siteNames: string[];
}) => {
  const prevSiteName = prevSite?.name ?? "";
  const prevSiteCity = prevSite?.city ?? "";
  const prevSiteType = prevSite?.type ?? "Standalone";
  const prevIntersectionId = prevSite?.intersectionId ?? "";
  const prevIntersectionRegion = prevSite?.roadRegulatorId ?? "";
  const prevLatitude = prevSite?.lat ?? "";
  const prevLongitude = prevSite?.lon ?? "";

  const [nextSiteName, setNextSiteName] = React.useState(prevSiteName);
  const [nextSiteCity, setNextSiteCity] = React.useState(prevSiteCity);
  const [nextSiteType, setNextSiteType] = React.useState(prevSiteType);
  const [nextIntersectionId, setNextIntersectionId] =
    React.useState(prevIntersectionId);
  const [nextIntersectionRegion, setNextIntersectionRegion] = React.useState(
    prevIntersectionRegion
  );
  const [nextLatitude, setNextLatitude] = React.useState(prevLatitude);
  const [nextLongitude, setNextLongitude] = React.useState(prevLongitude);

  const siteNameError = !nextSiteName.trim().length
    ? "*Site Name is required"
    : prevSite?.name !== nextSiteName && siteNames.includes(nextSiteName)
      ? "Site Name is already taken"
      : "";

  let prevSiteIntersectionIDAndRegion = "";
  let nextSiteIntersectionIdAndRegion = "";
  let intersectionError = "";

  if (prevSite?.type === "Intersection") {
    if (prevSite.intersectionId !== null) {
      prevSiteIntersectionIDAndRegion += prevSite.intersectionId;

      if (prevSite.roadRegulatorId !== null) {
        prevSiteIntersectionIDAndRegion += `:${prevSite.roadRegulatorId}`;
      }
    }
  }

  if (nextSiteType === "Intersection") {
    if (nextIntersectionId === "") {
      intersectionError = "*Intersection ID is required";
    } else {
      nextSiteIntersectionIdAndRegion += nextIntersectionId;

      if (nextIntersectionRegion !== "") {
        nextSiteIntersectionIdAndRegion += `:${nextIntersectionRegion}`;
      }

      if (
        prevSiteIntersectionIDAndRegion !== nextSiteIntersectionIdAndRegion &&
        intersectionIdsAndRegions.includes(nextSiteIntersectionIdAndRegion)
      ) {
        intersectionError =
          "Intersection ID + Intersection Region combination is already taken";
      }
    }
  }
  const latitudeError = nextLatitude === "" ? "*Latitude is required" : "";
  const longitudeError = nextLongitude === "" ? "*Longitude is required" : "";

  return (
    <Modal
      className="light"
      footer={
        <Button
          className="action lg"
          disabled={
            !!siteNameError ||
            !!intersectionError ||
            !!latitudeError ||
            !!longitudeError ||
            (prevSiteName === nextSiteName &&
              prevSiteCity === nextSiteCity &&
              prevSiteType === nextSiteType &&
              prevIntersectionId === nextIntersectionId &&
              prevIntersectionRegion === nextIntersectionRegion &&
              prevLatitude === nextLatitude &&
              prevLongitude === nextLongitude)
          }
          onClick={() =>
            confirmAction({
              deviceIds: prevSite?.deviceIds ?? [],
              id: prevSite?.id ?? generateRandomString(),
              intersectionId:
                nextSiteType === "Intersection"
                  ? parseInt(nextIntersectionId as string, 10)
                  : null,
              lat: nextLatitude as number,
              lon: nextLongitude as number,
              name: nextSiteName,
              city: nextSiteCity,
              roadRegulatorId:
                nextSiteType === "Intersection" &&
                  typeof nextIntersectionRegion === "number"
                  ? nextIntersectionRegion
                  : null,
              siteMetadata: {
                gateway: null,
                project_name: null,
                road_regulator_id:
                  nextSiteType === "Intersection" &&
                    typeof nextIntersectionRegion === "number"
                    ? nextIntersectionRegion
                    : null,
                subnet: null,
              } as any,
              type: nextSiteType,
            })
          }
          style={{ marginTop: 32 }}
        >
          CONFIRM
        </Button>
      }
      header={<h2>{headerText}</h2>}
    >
      <div
        style={{ width: "100%", height: 80, marginTop: 6, marginBottom: 24 }}
      >
        <Input
          autoFocus
          changed={!headerText.includes("New") && prevSiteName !== nextSiteName}
          id="site-name"
          label="Site Name"
          maxLength={200}
          onChange={(e) => setNextSiteName(e.target.value.slice(0, 200))}
          placeholder={
            nextSiteType === "Intersection"
              ? "Main St & 1st Avenue"
              : "Hwy 100 MM 123"
          }
          required
          type="text"
          validate={() => siteNameError}
          value={nextSiteName}
        />
      </div>
      <div style={{ width: 840, height: 64, marginBottom: 24 }}>
        <Radio
          changed={!headerText.includes("New") && prevSiteType !== nextSiteType}
          label="Site Type"
          name="site-type"
          onChange={(type) => setNextSiteType(type as any)}
          options={[
            {
              value: "Standalone",
            },
            {
              value: "Intersection",
            },
          ]}
          required
          value={nextSiteType}
        />
      </div>
      {nextSiteType === "Intersection" ? (
        <>
          <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
            <Input
              changed={
                !headerText.includes("New") &&
                prevIntersectionId !== nextIntersectionId
              }
              id="intersection-id"
              label="Intersection ID"
              max={65535}
              min={0}
              onChange={(e) => {
                const parsed = parseInt(e.target.value, 10);

                setNextIntersectionId(
                  Number.isNaN(parsed)
                    ? ("" as any)
                    : Math.min(65535, Math.max(0, parsed))
                );
              }}
              placeholder="e.g. 100"
              required
              type="number"
              validate={() => intersectionError}
              value={nextIntersectionId}
            />
          </div>
          <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
            <Input
              changed={
                !headerText.includes("New") &&
                prevIntersectionRegion !== nextIntersectionRegion
              }
              id="intersection-region"
              label="Intersection Region"
              max={65535}
              min={0}
              onChange={(e) => {
                const parsed = parseInt(e.target.value, 10);

                setNextIntersectionRegion(
                  Number.isNaN(parsed)
                    ? ("" as any)
                    : Math.min(65535, Math.max(0, parsed))
                );
              }}
              placeholder="e.g. 1"
              type="number"
              value={nextIntersectionRegion}
            />
          </div>
        </>
      ) : null}
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevLatitude !== nextLatitude}
          id="latitude"
          label="Latitude"
          max={90}
          min={-90}
          onChange={(e) => {
            const parsed = parseFloat(e.target.value);
            const nextValue =
              e.target.value === "-"
                ? "-"
                : Math.min(90, Math.max(-90, parseFloat(parsed.toFixed(8))));

            setNextLatitude(
              e.target.value !== "-" && Number.isNaN(parsed)
                ? ("" as any)
                : nextValue
            );
          }}
          placeholder="e.g. 39.80894"
          required
          step={0.000001}
          type="number"
          value={nextLatitude}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={
            !headerText.includes("New") && prevLongitude !== nextLongitude
          }
          id="longitude"
          label="Longitude"
          max={180}
          min={-180}
          onChange={(e) => {
            const parsed = parseFloat(e.target.value);
            const nextValue =
              e.target.value === "-"
                ? "-"
                : Math.min(180, Math.max(-180, parseFloat(parsed.toFixed(8))));

            setNextLongitude(
              e.target.value !== "-" && Number.isNaN(parsed)
                ? ("" as any)
                : nextValue
            );
          }}
          placeholder="e.g. -104.78054"
          required
          step={0.000001}
          type="number"
          value={nextLongitude}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevSiteCity !== nextSiteCity}
          id="site-city"
          label="Site City"
          maxLength={200}
          onChange={(e) => setNextSiteCity(e.target.value.slice(0, 200).toUpperCase())}
          placeholder="DENVER"
          type="text"
          value={nextSiteCity}
        />
      </div>
    </Modal>
  );
};

const DeviceModal = ({
  confirmAction,
  enrolledMakeModelSerialNumbers,
  headerText,
  ipAddresses,
  makeModelSerialNumbers,
  device: prevDevice,
  site,
}: {
  confirmAction: (device: Device) => void;
  enrolledMakeModelSerialNumbers: string[];
  headerText: string;
  ipAddresses: string[];
  makeModelSerialNumbers: string[];
  device?: Device;
  site: NormalizedSite;
}) => {
  const parsedMetadata = {
    managed_by: null,
    ping: false,
    snmp: false,
    ssh: false,
    ...(prevDevice?.deviceMetadata
      ? JSON.parse(prevDevice?.deviceMetadata)
      : {}),
  };
  const parsedSecrets = {
    snmp_auth_password: null,
    snmp_priv_password: null,
    snmp_port: null,
    snmp_username: null,
    ssh_password: null,
    ssh_username: null,
    ...(prevDevice?.deviceSecrets ? JSON.parse(prevDevice?.deviceSecrets) : {}),
  };

  const prevDeviceType = prevDevice?.deviceType ?? "RSU";
  const prevIpAddress = prevDevice?.ipAddress ?? "";
  const prevSerialNumber = prevDevice?.serialNumber ?? "";
  const prevMake = prevDevice?.make ?? "";
  const prevModel = prevDevice?.model ?? "";
  const prevEnrolled = prevDevice?.enrolled ?? true;
  const prevPingEnabled = parsedMetadata.ping;
  const prevSnmpEnabled = parsedMetadata.snmp;
  const prevSnmpUsername = parsedSecrets.snmp_username ?? "";
  const prevSnmpAuthPassword = parsedSecrets.snmp_auth_password ?? "";
  const prevSshEnabled = parsedMetadata.ssh;
  const prevSshUsername = parsedSecrets.ssh_username ?? "";
  const prevSshPassword = parsedSecrets.ssh_password ?? "";

  const prevMakeModelSerialNumber =
    typeof prevMake === "string" &&
      typeof prevModel === "string" &&
      typeof prevSerialNumber === "string"
      ? `${[prevDeviceType, prevMake, prevModel]
        .join(",")
        .toUpperCase()},${prevSerialNumber}`
      : null;

  const [nextDeviceType, setNextDeviceType] = React.useState(prevDeviceType);
  const [nextIpAddress, setNextIpAddress] = React.useState(prevIpAddress);
  const [nextSerialNumber, setNextSerialNumber] =
    React.useState(prevSerialNumber);
  const [nextMake, setNextMake] = React.useState(prevMake);
  const [nextModel, setNextModel] = React.useState(prevModel);
  const [nextEnrolled, setNextEnrolled] = React.useState(prevEnrolled);
  const [nextPingEnabled, setNextPingEnabled] = React.useState(prevPingEnabled);
  const [nextSnmpEnabled, setNextSnmpEnabled] = React.useState(prevSnmpEnabled);
  const [nextSnmpUsername, setNextSnmpUsername] =
    React.useState(prevSnmpUsername);
  const [nextSnmpAuthPassword, setNextSnmpAuthPassword] =
    React.useState(prevSnmpAuthPassword);
  const [nextSshEnabled, setNextSshEnabled] = React.useState(prevSshEnabled);
  const [nextSshUsername, setNextSshUsername] = React.useState(prevSshUsername);
  const [nextSshPassword, setNextSshPassword] = React.useState(prevSshPassword);

  const nextMakeModelSerialNumber =
    typeof nextMake === "string" &&
      typeof nextModel === "string" &&
      typeof nextSerialNumber === "string"
      ? `${[nextDeviceType, nextMake, nextModel]
        .join(",")
        .toUpperCase()},${nextSerialNumber}`
      : null;

  const ipAddressError = !nextIpAddress.length
    ? "*IP Address is required"
    : !validateIpAddress(nextIpAddress)
      ? "IP Address format is invalid"
      : (!prevEnrolled || prevIpAddress !== nextIpAddress) &&
        ipAddresses.includes(nextIpAddress)
        ? "IP Address is already taken"
        : "";
  const serialNumberError =
    typeof nextMakeModelSerialNumber === "string" &&
      (!prevEnrolled ||
        prevMakeModelSerialNumber !== nextMakeModelSerialNumber) &&
      enrolledMakeModelSerialNumbers.includes(nextMakeModelSerialNumber)
      ? "Make + Model + Serial Number combination is already taken"
      : "";
  const snmpUsernameError =
    nextSnmpEnabled && !nextSnmpUsername.length
      ? "*SNMP Username is required"
      : "";
  const snmpPasswordError = !nextSnmpEnabled
    ? ""
    : !nextSnmpAuthPassword.length
      ? "*SNMP Password is required"
      : nextSnmpAuthPassword.length < 8
        ? "SNMP Password must contain at least 8 characters"
        : "";
  const sshUsernameError =
    nextSshEnabled && !nextSshUsername.length
      ? "*SSH Username is required"
      : "";
  const sshPasswordError =
    nextSshEnabled && !nextSshPassword.length
      ? "*SSH Password is required"
      : "";

  const allMakes = [
    ...(new Set(makeModelSerialNumbers.map((mms) => mms.split(",")[1])) as any),
  ].sort();
  let filteredMakes = allMakes.filter(
    (make) =>
      makeModelSerialNumbers.find((mms) =>
        mms.startsWith(`${nextDeviceType.toUpperCase()},${make},`)
      ) !== undefined
  );

  if (nextModel) {
    filteredMakes = filteredMakes.filter(
      (make) =>
        makeModelSerialNumbers.find((mms) =>
          mms.startsWith(`${nextDeviceType.toUpperCase()},${make},${nextModel}`)
        ) !== undefined
    );
  }
  const allModels = [
    ...(new Set(makeModelSerialNumbers.map((mms) => mms.split(",")[2])) as any),
  ].sort();
  let filteredModels = allModels.filter((model) =>
    makeModelSerialNumbers.find(
      (mms) =>
        mms.startsWith(`${nextDeviceType.toUpperCase()},`) &&
        mms.includes(`,${model}`)
    )
  );

  if (nextMake) {
    filteredModels = filteredModels.filter(
      (model) =>
        makeModelSerialNumbers.find((mms) =>
          mms.startsWith(
            `${nextDeviceType.toUpperCase()},${nextMake.toUpperCase()},${model}`
          )
        ) !== undefined
    );
  }
  const isEnrollable =
    prevEnrolled ||
    (!ipAddresses.includes(nextIpAddress) &&
      (nextMakeModelSerialNumber === null ||
        !enrolledMakeModelSerialNumbers.includes(nextMakeModelSerialNumber)));

  return (
    <Modal
      className="light"
      header={<h2>{headerText}</h2>}
      footer={
        <Button
          className="action lg"
          disabled={
            !isEnrollable ||
            !!ipAddressError ||
            !!serialNumberError ||
            !!snmpUsernameError ||
            !!snmpPasswordError ||
            !!sshUsernameError ||
            !!sshPasswordError ||
            (prevDeviceType === nextDeviceType &&
              prevIpAddress === nextIpAddress &&
              prevSerialNumber === nextSerialNumber &&
              prevMake === nextMake &&
              prevModel === nextModel &&
              prevEnrolled === nextEnrolled &&
              prevPingEnabled === nextPingEnabled &&
              prevSnmpEnabled === nextSnmpEnabled &&
              prevSnmpUsername === nextSnmpUsername &&
              prevSnmpAuthPassword === nextSnmpAuthPassword &&
              prevSshEnabled === nextSshEnabled &&
              prevSshUsername === nextSshUsername &&
              prevSshPassword === nextSshPassword)
          }
          onClick={() => {
            const nextDevice = {
              changedAt: new Date().getTime(),
              deviceClass: "FIXED" as const,
              deviceMetadata: {
                ...(prevDevice?.deviceMetadata
                  ? JSON.parse(prevDevice.deviceMetadata)
                  : {}),
                managed_by: null, // TODO: Put tenant value here?
                ping: nextPingEnabled,
              },
              deviceSecrets: prevDevice?.deviceSecrets
                ? JSON.parse(prevDevice.deviceSecrets)
                : {},
              deviceType: nextDeviceType,
              enrolled: nextEnrolled,
              id: prevDevice?.id ?? generateRandomString(),
              ipAddress: nextIpAddress,
              make: nextMake as string,
              model: nextModel as string,
              serialNumber: nextSerialNumber as string,
              siteId: site.id,
              snmpUsername: nextSnmpUsername,
              sshUsername: nextSshUsername,
            };

            if (deviceSupportsSnmp(nextDevice)) {
              nextDevice.deviceMetadata.snmp = nextSnmpEnabled;

              nextDevice.deviceSecrets.snmp_auth_password = nextSnmpAuthPassword;

              if (!nextDevice.deviceSecrets.snmp_auth_protocol) {
                nextDevice.deviceSecrets.snmp_auth_protocol = "SHA";
              }

              if (!nextDevice.deviceSecrets.snmp_level) {
                nextDevice.deviceSecrets.snmp_level = "authPriv";
              }

              if (!nextDevice.deviceSecrets.snmp_port) {
                nextDevice.deviceSecrets.snmp_port = 161;
              }
              nextDevice.deviceSecrets.snmp_priv_password = nextSnmpAuthPassword; // Auth and Priv password are tied to the same user input here

              if (!nextDevice.deviceSecrets.snmp_priv_protocol) {
                nextDevice.deviceSecrets.snmp_priv_protocol = "AES";
              }
              nextDevice.deviceSecrets.snmp_username = nextSnmpUsername;
            }

            if (deviceSupportsSsh(nextDevice)) {
              nextDevice.deviceMetadata.ssh = nextSshEnabled;
              nextDevice.deviceSecrets.ssh_password = nextSshPassword;
              nextDevice.deviceSecrets.ssh_username = nextSshUsername;
            }
            confirmAction(nextDevice);
          }}
          style={{ marginTop: 32 }}
        >
          CONFIRM
        </Button>
      }
    >
      <div
        style={{
          height: 64,
          marginTop: 6,
          marginBottom: 24,
          overflow: "visible",
        }}
      >
        <Radio
          changed={
            !headerText.includes("New") && prevDeviceType !== nextDeviceType
          }
          label="Device Type"
          name="device-type"
          onChange={(type) => setNextDeviceType(type as any)}
          options={[
            {
              label: getDeviceTypeLabel("SWITCH"),
              value: "SWITCH",
            },
            {
              label: getDeviceTypeLabel("RSU"),
              value: "RSU",
            },
            {
              label: getDeviceTypeLabel("CVP"),
              value: "CVP",
            },
            {
              label: getDeviceTypeLabel("TSC"),
              value: "TSC",
            },
          ]}
          required
          value={nextDeviceType}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          autoFocus
          changed={
            !headerText.includes("New") && prevIpAddress !== nextIpAddress
          }
          id="ip-address"
          label="IP Address"
          onChange={(e) => {
            const parts = e.target.value
              .split(".")
              .map((part, idx) =>
                part === "" || Number.isNaN(parseInt(part, 10))
                  ? ""
                  : parseInt(part, 10).toString().length === 4 && idx < 3
                    ? `${Math.min(
                      255,
                      Math.max(
                        0,
                        parseInt(parseInt(part, 10).toString().slice(0, 3), 10)
                      )
                    )}.${Math.min(
                      255,
                      Math.max(
                        0,
                        parseInt(parseInt(part, 10).toString()[3], 10)
                      )
                    )}`
                    : Math.min(255, Math.max(0, parseInt(part, 10)))
              );

            setNextIpAddress(parts.slice(0, 4).join("."));
          }}
          placeholder="e.g. 1.1.1.1"
          required
          type="text"
          validate={() => ipAddressError}
          value={nextIpAddress}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={
            !headerText.includes("New") && prevSerialNumber !== nextSerialNumber
          }
          id="serial-number"
          label="Serial Number"
          maxLength={200}
          onChange={(e) => setNextSerialNumber(e.target.value.replace(",", ""))}
          placeholder="e.g. ABC000001"
          type="text"
          validate={() => serialNumberError}
          value={nextSerialNumber}
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevMake !== nextMake}
          id="make"
          label="Make"
          maxLength={200}
          onChange={(e) =>
            setNextMake(e.target.value.toUpperCase().replace(",", ""))
          }
          placeholder="e.g. ACME"
          recommendations={filteredMakes}
          type="text"
          value={nextMake}
          warning={
            !!nextMake && !allMakes.includes(nextMake.toUpperCase())
              ? "New Make"
              : ""
          }
        />
      </div>
      <div style={{ width: "100%", height: 80, marginBottom: 24 }}>
        <Input
          changed={!headerText.includes("New") && prevModel !== nextModel}
          id="model"
          label="Model"
          maxLength={200}
          onChange={(e) =>
            setNextModel(e.target.value.toUpperCase().replace(",", ""))
          }
          placeholder="e.g. ABC-1234"
          recommendations={filteredModels}
          type="text"
          value={nextModel}
          warning={
            allModels.includes(nextModel?.toUpperCase()) &&
              !filteredModels.includes(nextModel?.toUpperCase())
              ? "Model might belong to another Make"
              : !!nextModel && !allModels.includes(nextModel.toUpperCase())
                ? "New Model"
                : ""
          }
        />
      </div>
      <div>
        <label>Network Access</label>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Toggle
            changed={
              !headerText.includes("New") && prevEnrolled !== nextEnrolled
            }
            checked={nextEnrolled}
            id="enrolled"
            onChange={() => setNextEnrolled(!nextEnrolled)}
          />
          <div style={{ marginLeft: 18 }}>
            <label htmlFor="enrolled" style={{ cursor: "pointer" }}>
              {nextEnrolled ? "" : "Not "}Enrolled
            </label>
            <p style={{ marginTop: 4 }}>
              Enrolled Devices are Devices that Cirrus should track and
              communicate with. Enrolled Devices incur service charges according
              to usage and plan details. Unenrolled Device configurations will
              be kept for easy reenrollment.
            </p>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", marginTop: 24 }}>
          <Toggle
            changed={
              !headerText.includes("New") && prevPingEnabled !== nextPingEnabled
            }
            checked={nextPingEnabled}
            id="ping-enabled"
            onChange={() => setNextPingEnabled(!nextPingEnabled)}
          />
          <div style={{ marginLeft: 18 }}>
            <label htmlFor="ping-enabled" style={{ cursor: "pointer" }}>
              Ping{" "}
              {nextPingEnabled
                ? `Enabled${nextEnrolled ? "" : " (Not Enrolled)"}`
                : "Disabled"}
            </label>
            <p style={{ marginTop: 4 }}>
              The Cirrus ping service automates routine pings to the IP
              addresses of configured and enabled Devices in order to check
              connectivity. Successful pings confirm reachability on the network
              and failed pings can generate health alerts in downstream
              applications.
            </p>
          </div>
        </div>
        {["RSU"].includes(nextDeviceType) ? (
          <>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 24 }}
            >
              <Toggle
                changed={
                  !headerText.includes("New") &&
                  prevSnmpEnabled !== nextSnmpEnabled
                }
                checked={nextSnmpEnabled}
                id="snmp-enabled"
                onChange={() => setNextSnmpEnabled(!nextSnmpEnabled)}
              />
              <div style={{ marginLeft: 18 }}>
                <label htmlFor="snmp-enabled" style={{ cursor: "pointer" }}>
                  SNMP{" "}
                  {nextSnmpEnabled
                    ? `Enabled${nextEnrolled ? "" : " (Not Enrolled)"}`
                    : "Disabled"}
                </label>
                <p style={{ marginTop: 4 }}>
                  Simple Network Management Protocol (SNMP) is a standard
                  networking protocol used to manage and monitor configured and
                  enabled Devices. Depending on Device type and standards
                  conformance, SNMP can be used to collect detailed health and
                  status data or to issue commands to Devices.
                </p>
              </div>
            </div>
            {nextSnmpEnabled ||
              nextSnmpUsername.length ||
              nextSnmpAuthPassword.length ? (
              <div
                style={{
                  height: 80,
                  marginTop: 24,
                  marginLeft: 46,
                }}
              >
                <Input
                  changed={
                    !headerText.includes("New") &&
                    prevSnmpUsername !== nextSnmpUsername
                  }
                  disabled={!nextSnmpEnabled}
                  id="snmp-username"
                  label="SNMP Username"
                  maxLength={200}
                  onChange={(e) => setNextSnmpUsername(e.target.value)}
                  placeholder="e.g. test"
                  required
                  type="text"
                  value={nextSnmpUsername}
                />
              </div>
            ) : null}
            {nextSnmpEnabled ||
              nextSnmpUsername.length ||
              nextSnmpAuthPassword.length ? (
              <div
                style={{
                  height: 80,
                  marginTop: 24,
                  marginLeft: 46,
                }}
              >
                <Input
                  changed={
                    !headerText.includes("New") &&
                    prevSnmpAuthPassword !== nextSnmpAuthPassword
                  }
                  disabled={!nextSnmpEnabled}
                  id="snmp-password"
                  label="SNMP Password"
                  maxLength={200}
                  onChange={(e) => setNextSnmpAuthPassword(e.target.value)}
                  required
                  type="password"
                  validate={() => snmpPasswordError}
                  value={nextSnmpAuthPassword}
                  warning={
                    validatePasswordComplexity(nextSnmpAuthPassword)
                      ? ""
                      : "At least 1 uppercase letter, 1 lowercase letter, 1 number, and 8 total characters are recommended"
                  }
                />
              </div>
            ) : null}
          </>
        ) : null}
        {["RSU"].includes(nextDeviceType) ? (
          <>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 24 }}
            >
              <Toggle
                changed={
                  !headerText.includes("New") &&
                  prevSshEnabled !== nextSshEnabled
                }
                checked={nextSshEnabled}
                id="ssh-enabled"
                onChange={() => setNextSshEnabled(!nextSshEnabled)}
              />
              <div style={{ marginLeft: 18 }}>
                <label htmlFor="ssh-enabled" style={{ cursor: "pointer" }}>
                  SSH{" "}
                  {nextSshEnabled
                    ? `Enabled${nextEnrolled ? "" : " (Not Enrolled)"}`
                    : "Disabled"}
                </label>
                <p style={{ marginTop: 4 }}>
                  Secure Shell (SSH) protocol is a method for sending custom
                  commands to configured and enabled Devices. Depending on
                  Device type, some commands can be used to fetch health and
                  status data or to issue commands to Devices.
                </p>
              </div>
            </div>
            {nextSshEnabled ||
              nextSshUsername.length ||
              nextSshPassword.length ? (
              <div
                style={{
                  height: 80,
                  marginTop: 24,
                  marginLeft: 46,
                }}
              >
                <Input
                  changed={
                    !headerText.includes("New") &&
                    prevSshUsername !== nextSshUsername
                  }
                  disabled={!nextSshEnabled}
                  id="ssh-username"
                  label="SSH Username"
                  maxLength={200}
                  onChange={(e) => setNextSshUsername(e.target.value)}
                  placeholder="e.g. test"
                  required
                  type="text"
                  value={nextSshUsername}
                />
              </div>
            ) : null}
            {nextSshEnabled ||
              nextSshUsername.length ||
              nextSshPassword.length ? (
              <div
                style={{
                  height: 80,
                  marginTop: 24,
                  marginLeft: 46,
                }}
              >
                <Input
                  changed={
                    !headerText.includes("New") &&
                    prevSshPassword !== nextSshPassword
                  }
                  disabled={!nextSshEnabled}
                  id="ssh-password"
                  label="SSH Password"
                  maxLength={200}
                  onChange={(e) => setNextSshPassword(e.target.value)}
                  type="password"
                  required
                  value={nextSshPassword}
                  warning={
                    validatePasswordComplexity(nextSshPassword)
                      ? ""
                      : "At least 1 uppercase letter, 1 lowercase letter, 1 number, and 8 total characters are recommended"
                  }
                />
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </Modal>
  );
};

export const RegisterNewSiteModal = ({
  confirmAction,
  intersectionIdsAndRegions,
  siteNames,
}: {
  confirmAction: (site: NormalizedSite) => void;
  intersectionIdsAndRegions: string[];
  siteNames: string[];
}) => (
  <SiteModal
    confirmAction={confirmAction}
    headerText="Register New Site"
    intersectionIdsAndRegions={intersectionIdsAndRegions}
    siteNames={siteNames}
  />
);

export const EditSiteModal = ({
  confirmAction,
  intersectionIdsAndRegions,
  site,
  siteNames,
}: {
  confirmAction: (site: NormalizedSite) => void;
  intersectionIdsAndRegions: string[];
  site: NormalizedSite;
  siteNames: string[];
}) => (
  <SiteModal
    confirmAction={confirmAction}
    headerText="Edit Site"
    intersectionIdsAndRegions={intersectionIdsAndRegions}
    site={site}
    siteNames={siteNames}
  />
);

export const RegisterNewDeviceModal = ({
  confirmAction,
  enrolledMakeModelSerialNumbers,
  ipAddresses,
  makeModelSerialNumbers,
  site,
}: {
  confirmAction: (device: Device) => void;
  enrolledMakeModelSerialNumbers: string[];
  ipAddresses: string[];
  makeModelSerialNumbers: string[];
  site: NormalizedSite;
}) => (
  <DeviceModal
    confirmAction={confirmAction}
    enrolledMakeModelSerialNumbers={enrolledMakeModelSerialNumbers}
    headerText="Register New Device"
    ipAddresses={ipAddresses}
    makeModelSerialNumbers={makeModelSerialNumbers}
    site={site}
  />
);

export const EditDeviceModal = ({
  confirmAction,
  device,
  enrolledMakeModelSerialNumbers,
  ipAddresses,
  makeModelSerialNumbers,
  site,
}: {
  confirmAction: (device: Device) => void;
  device: Device;
  enrolledMakeModelSerialNumbers: string[];
  ipAddresses: string[];
  makeModelSerialNumbers: string[];
  site: NormalizedSite;
}) => (
  <DeviceModal
    confirmAction={confirmAction}
    device={device}
    enrolledMakeModelSerialNumbers={enrolledMakeModelSerialNumbers}
    headerText="Edit Device"
    ipAddresses={ipAddresses}
    makeModelSerialNumbers={makeModelSerialNumbers}
    site={site}
  />
);

export const RemoveDeviceModal = ({
  confirmAction,
  device,
}: {
  confirmAction: (device: Device) => void;
  device: Device;
}) => (
  <Modal
    className="light"
    footer={
      <Button className="action lg" onClick={() => confirmAction(device)}>
        CONFIRM
      </Button>
    }
    header={
      <h2 style={{ fontSize: "24px" }}>
        Are you sure you want to remove this Device?
      </h2>
    }
  >
    <p style={{ marginBottom: 42, fontSize: "16px", color: "#161616" }}>
      This action cannot be undone. Once a Device has been removed from a Site,
      it will need to be registered again from the Site Detail view.
    </p>
  </Modal>
);
